import React, { useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import Product from '../components/Product';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import './searchScreen.css';

const fetchFilterConfigurations = async () => {
  try {
    const response = await fetch(`/api/products/filterParameters`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch filter configurations:', error);
    throw error;
  }
};

const fetchSortParameters = async () => {
  try {
    const response = await fetch(`/api/products/sortParameters`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch sort parameters:', error);
    throw error;
  }
};

const initialState = {
  products: [],
  page: 0,
  pages: 0,
  countProducts: 0,
  filterRenderItems: {},
  loading: true,
  error: null,
};

const reducer = (state, action) => {
  console.log('Action dispatched:', action.type);
  switch (action.type) {
    case 'FETCH_INIT':
      console.log('FETCH_INIT');
      return { ...initialState, loading: true };
    case 'FETCH_PRODUCTS_SUCCESS':
      console.log('FETCH_PRODUCTS_SUCCESS', action.payload);
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.limitedPages,
        countProducts: action.payload.countProducts,
        loading: false, // Ensure loading is set to false here
        error: null,
      };
    case 'FETCH_FILTER_ITEM_SUCCESS':
      console.log('FETCH_FILTER_ITEM_SUCCESS', action.payload);
      return {
        ...state,
        filterRenderItems: {
          ...state.filterRenderItems,
          ...action.payload,
        },
      };
    case 'FETCH_FAIL':
      console.log('FETCH_FAIL', action.payload);
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function SearchScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse selected filters from URL
  const getSelectedFiltersFromURL = () => {
    const sp = new URLSearchParams(location.search);
    const filters = {};
    for (const [key, value] of sp.entries()) {
      if (['query', 'page', 'order', 'newSearch'].includes(key)) continue;
      filters[key] = value.split(',');
    }
    return filters;
  };

  const query = new URLSearchParams(location.search).get('query') || 'all';
  const page = new URLSearchParams(location.search).get('page') || 1;
  const order =
    new URLSearchParams(location.search).get('order') || 'findfast_rank';
  const [category, setCategory] = useState(
    new URLSearchParams(location.search).get('categories_flat') || ''
  );

  const [
    { loading, error, products, pages, countProducts, filterRenderItems },
    dispatch,
  ] = useReducer(reducer, initialState);

  const [sortParameters, setSortParameters] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(true);

  // **selectedFilters** represent the filters currently applied from URL
  const [selectedFilters, setSelectedFilters] = useState(
    getSelectedFiltersFromURL()
  );

  // **pendingFilters** represent the user's chosen filters that are not yet applied.
  // Initially, pendingFilters match selectedFilters.
  const [pendingFilters, setPendingFilters] = useState(
    getSelectedFiltersFromURL()
  );

  useEffect(() => {
    const loadSortParameters = async () => {
      try {
        const sortParams = await fetchSortParameters();
        setSortParameters(sortParams);
      } catch (error) {
        toast.error(getError(error));
      }
    };

    loadSortParameters();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const loadFilterConfigs = async () => {
      try {
        const configs = await fetchFilterConfigurations();
        setFilterConfigs(configs);
      } catch (error) {
        console.error('Failed to load filter configurations:', error);
      } finally {
        setFiltersLoading(false); // Ensure the loading state is set to false after fetching
      }
    };
    loadFilterConfigs();
  }, []);

  // Update selectedFilters state when URL changes
  useEffect(() => {
    setSelectedFilters(getSelectedFiltersFromURL());
    setShowFilters(false);
    // Also reset pendingFilters to match the now-applied filters
    setPendingFilters(getSelectedFiltersFromURL());
  }, [location.search]);

  const [selectedGroupFilters, setSelectedGroupFilters] = useState({});

  useEffect(() => {
    console.log(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        // Parse filters directly from URL
        const sp = new URLSearchParams(location.search);
        const query = sp.get('query') || 'all';
        const page = sp.get('page') || 1;
        const order = sp.get('order') || 'findfast_rank';
        const selectedFiltersFromUrl = {};
        for (const [key, value] of sp.entries()) {
          if (!['query', 'page', 'order', 'newSearch'].includes(key)) {
            selectedFiltersFromUrl[key] = value.split(',');
          }
        }

        let queryString = Object.entries(selectedFiltersFromUrl)
          .reduce((acc, [key, values]) => {
            if (values.length > 0) {
              acc.push(`${key}=${values.join(',')}`);
            }
            return acc;
          }, [])
          .join('&');

        const newSearchParam = sp.get('newSearch')
          ? `&newSearch=${sp.get('newSearch')}`
          : '';

        if (queryString) {
          queryString = `&${queryString}`;
        }

        const response = await fetch(
          `/api/products/search?page=${page}&query=${query}&order=${order}${queryString}${newSearchParam}`
        );

        if (!response.body) {
          throw new Error('ReadableStream not yet supported in this browser.');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        let isInitialDataReceived = false;

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });

          let lines = buffer.split('\n');
          buffer = lines.pop(); // Save incomplete line for next chunk

          for (const line of lines) {
            if (line.trim()) {
              try {
                const data = JSON.parse(line);
                if (!isInitialDataReceived && data.products) {
                  // Initial data received
                  dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: data });
                  isInitialDataReceived = true;
                } else {
                  // Filter item received
                  if (data) {
                    Object.keys(data).forEach((key) => {
                      const currentFilterConfig = filterConfigs.find(
                        (config) =>
                          config.key === key &&
                          config.renderType === 'hierarchy'
                      );
                      if (currentFilterConfig) {
                        const newSelectedItems = [
                          ...(selectedFilters[key] || []),
                        ];
                        data[key].forEach((item) => {
                          if (
                            isParentSelected(
                              item._id,
                              selectedFilters[key] || []
                            )
                          ) {
                            if (!newSelectedItems.includes(item._id)) {
                              newSelectedItems.push(item._id);
                            }
                            ensureParentsSelected(item._id, newSelectedItems);
                          }
                        });
                        if (
                          newSelectedItems.length >
                          (selectedFilters[key] || []).length
                        ) {
                          const updatedFilters = {
                            ...selectedFilters,
                            [key]: newSelectedItems,
                          };
                          navigate(
                            getFilterUrl({
                              selectedFilters: updatedFilters,
                              page: 1,
                            }),
                            { replace: true }
                          );
                        }
                      }
                    });
                  }
                  dispatch({
                    type: 'FETCH_FILTER_ITEM_SUCCESS',
                    payload: data,
                  });
                }
              } catch (error) {
                console.error('Error parsing JSON line:', error);
              }
            }
          }
        }

        // Process any remaining buffer
        if (buffer.trim()) {
          try {
            const data = JSON.parse(buffer);
            if (!isInitialDataReceived && data.products) {
              dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: data });
            } else {
              dispatch({
                type: 'FETCH_FILTER_ITEM_SUCCESS',
                payload: data,
              });
            }
          } catch (error) {
            console.error('Error parsing remaining buffer:', error);
          }
        }
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [order, page, query, location.search]); // Depend on location.search instead of selectedFilters

  function ensureParentsSelected(item, selectedItems) {
    if (!item || !selectedItems) return false;
    let currentKey = item;
    while (currentKey) {
      const parentKey = getParentKey(currentKey);
      if (
        parentKey &&
        isParentSelected(parentKey, selectedItems) &&
        !selectedItems.includes(parentKey)
      ) {
        selectedItems.push(parentKey);
      }
      currentKey = parentKey;
    }
  }

  // Helper function to recursively check if any parent of the item is selected
  function isParentSelected(item, selectedItems) {
    if (!item || !selectedItems) return false;
    let parentKey = getParentKey(item);
    while (parentKey) {
      if (selectedItems.includes(parentKey)) {
        return true;
      }
      parentKey = getParentKey(parentKey);
    }
    return false;
  }

  // Function to derive parent key by removing last underscore and subsequent text
  function getParentKey(item) {
    if (!item || !item.includes('_')) return null;
    return item.substring(0, item.lastIndexOf('_'));
  }

  const handleFilterChange = (filterType, value, isChecked, clear) => {
    const updatedFilters = { ...pendingFilters };

    if (clear) {
      updatedFilters[filterType] = isChecked ? [value] : [];
    } else {
      if (!updatedFilters[filterType]) {
        updatedFilters[filterType] = [];
      }

      if (isChecked) {
        // Add the value to the filter array if checked
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      } else {
        // Remove the value from the filter array if unchecked
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      }
    }

    setPendingFilters(updatedFilters);
  };

  // Assumes setSelectedFilters is a state updater function for an object
  // where keys are filter categories and values are arrays of selected filter values.

  // Updates the state to reflect changes in hierarchical selections
  const handleHierarchyChange = (categoryKey, itemKey, isChecked, subItems) => {
    const updatedFilters = { ...selectedFilters };

    // Ensure the category array exists
    if (!updatedFilters[categoryKey]) {
      updatedFilters[categoryKey] = [];
    }

    const toggleSelection = (key, isChecked) => {
      if (isChecked && !updatedFilters[categoryKey].includes(key)) {
        updatedFilters[categoryKey].push(key);
      } else if (!isChecked) {
        updatedFilters[categoryKey] = updatedFilters[categoryKey].filter(
          (item) => item !== key
        );
      }
    };

    // Recursively toggle sub-items
    const toggleSubItems = (key, isChecked, subItems) => {
      toggleSelection(key, isChecked);
      if (typeof subItems === 'object' && Object.keys(subItems).length > 0) {
        Object.keys(subItems).forEach((subKey) => {
          const fullKey = `${key}_${subKey}`;
          toggleSubItems(fullKey, isChecked, subItems[subKey]);
        });
      }
    };

    // Start the recursion with the initial item key
    toggleSubItems(itemKey, isChecked, subItems);
    // Ensure all parents are deselected if a child is deselected
    if (!isChecked) {
      const parts = itemKey.split('_');
      while (parts.length > 1) {
        parts.pop(); // Remove the last part to go up one level
        const parentKey = parts.join('_');
        const anySelected = updatedFilters[categoryKey].some((k) =>
          k.startsWith(parentKey + '_')
        );
        if (anySelected) {
          toggleSelection(parentKey, false);
        }
      }
    }

    navigate(getFilterUrl({ selectedFilters: updatedFilters, page: 1 }));
  };

  const handleDropdownMenuChange = (
    filterConfig,
    selectedFirst,
    selectedSecond
  ) => {
    const updatedFilters = { ...pendingFilters };
    filterConfig.key.forEach((k) => {
      delete updatedFilters[k];
    });

    if (selectedFirst === 'any') {
      setPendingFilters(updatedFilters);
      return;
    }

    // If selected second is "any", apply all possible sizes from that dimension
    const allSizes = filterConfig.options[selectedFirst]; // all sizes for that dimension
    if (selectedSecond === 'any') {
      // Apply all sizes from the corresponding key
      const dimensionIndex =
        filterConfig.firstDropdownOptions.indexOf(selectedFirst);
      const dimensionKey = filterConfig.key[dimensionIndex - 1];
      // -1 because firstDropdownOptions includes 'any' at index 0
      // keys array is aligned to men,women,kids,toddlers in order at indexes 0,1,2,3
      updatedFilters[dimensionKey] = allSizes.map((size) => String(size));
    } else {
      // Apply just the selected size
      const dimensionIndex =
        filterConfig.firstDropdownOptions.indexOf(selectedFirst);
      const dimensionKey = filterConfig.key[dimensionIndex - 1];
      updatedFilters[dimensionKey] = [String(selectedSecond)];
    }

    setPendingFilters(updatedFilters);
  };

  const getFilterUrl = ({
    selectedFilters: filters = selectedFilters,
    page: filterPage = page,
    order: filterOrder = order,
    query: filterQuery = query,
  }) => {
    let queryParams = new URLSearchParams({
      page: filterPage,
      query: filterQuery,
      order: filterOrder,
    });

    Object.entries(filters).forEach(([key, values]) => {
      if (values.length) {
        queryParams.set(key, values.join(','));
      }
    });

    return `/search?${queryParams.toString()}`;
  };

  const RenderFilter = ({ filterConfig, filterData, groupedFilters }) => {
    const {
      data,
      displayName,
      renderType,
      valueKey,
      groupName,
      options,
      firstDropdownOptions,
      key: configKey,
    } = filterConfig;

    // Add useState call here for toggling display items in the checkbox list
    const [showAll, setShowAll] = useState(false); // State to toggle the visibility of all items

    // Local state for drop_down_menu selections
    const [selectedFirst, setSelectedFirst] = useState('any');
    const [selectedSecond, setSelectedSecond] = useState('any');

    // Pre-populate the dropdown state from the URL selectedFilters
    useEffect(() => {
      if (renderType === 'drop_down_menu') {
        // Check if any of the keys are set in selectedFilters
        let foundDimension = 'any';
        let foundSize = 'any';
        filterConfig.key.forEach((dimensionKey, index) => {
          if (
            pendingFilters[dimensionKey] &&
            pendingFilters[dimensionKey].length > 0
          ) {
            // This dimension is selected
            foundDimension = filterConfig.firstDropdownOptions[index + 1];
            // +1 because 'any' is at index 0 in firstDropdownOptions
            if (
              pendingFilters[dimensionKey].length ===
              filterConfig.options[foundDimension].length
            ) {
              // All sizes selected => second dropdown = 'any'
              foundSize = 'any';
            } else if (pendingFilters[dimensionKey].length === 1) {
              foundSize = pendingFilters[dimensionKey][0];
            } else {
              // Multiple sizes selected, which scenario might not arise as per the specs,
              // but let's just pick 'any' for now.
              foundSize = 'any';
            }
          }
        });
        setSelectedFirst(foundDimension);
        setSelectedSecond(foundSize);
      }
    }, [pendingFilters]);

    if (valueKey === 'name') {
      filterData = filterData.map((item) => ({
        name: item._id,
        count: item.count,
      }));
    } else {
      filterData = data;
    }

    // Parse filterData into a hierarchical structure
    function parseHierarchy(filterData) {
      const hierarchy = {};
      filterData.forEach((item) => {
        const levels = item.split('_');
        let currentLevel = hierarchy;
        levels.forEach((level, index) => {
          if (!currentLevel[level]) {
            currentLevel[level] = index === levels.length - 1 ? {} : {}; // Initialize as object for next level
          }
          currentLevel = currentLevel[level];
        });
      });
      return hierarchy;
    }
    const isSelected = (categoryKey, itemKey) => {
      const selected = selectedFilters[categoryKey] || [];

      // Check if the item is directly selected
      if (selected.includes(itemKey)) {
        return true;
      }
      return false;
    };

    // Determine if item is selected
    // For hierarchical filters use selectedFilters.
    // For others, use pendingFilters.
    const isItemSelected = (categoryKey, itemKey, isHierarchy = false) => {
      const filtersToUse = isHierarchy ? selectedFilters : pendingFilters;
      const selected = filtersToUse[categoryKey] || [];
      return selected.includes(itemKey);
    };

    switch (renderType) {
      case 'list':
        return (
          <div>
            <h3>{displayName}</h3>
            <ul>
              {filterData.map((item) => (
                <li
                  key={item[valueKey]}
                  style={{ cursor: 'pointer' }} // Add cursor style for better UX
                  className={
                    pendingFilters[configKey]?.includes(item[valueKey])
                      ? 'text-bold'
                      : ''
                  }
                  onClick={() => {
                    const currentlySelected = pendingFilters[
                      configKey
                    ]?.includes(item[valueKey]);
                    handleFilterChange(
                      configKey,
                      item[valueKey],
                      !currentlySelected,
                      false
                    );
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        );

      case 'checkboxList':
        const visibleData = showAll ? filterData : filterData.slice(0, 10);
        return (
          <div>
            <h3>{displayName}</h3>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {visibleData.map((item) => (
                <li key={item[valueKey]}>
                  <label style={{ display: 'block', marginBottom: '8px' }}>
                    <input
                      type="checkbox"
                      value={item[valueKey]}
                      checked={
                        pendingFilters[configKey]?.includes(
                          String(item[valueKey])
                        ) || false
                      }
                      onChange={(e) =>
                        handleFilterChange(
                          configKey,
                          item[valueKey],
                          e.target.checked,
                          false
                        )
                      }
                      style={{ marginRight: '8px' }}
                    />
                    <span style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
                      {item.name}
                      {valueKey === 'name' ? ' (' + item.count + ')' : ''}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
            {filterData.length > 10 && (
              <button
                class="custom-button"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
        );

      case 'hierarchy':
        // Parse the filterData into a hierarchical structure
        const hierarchyData = parseHierarchy(
          filterData.map((item) => item.name)
        );

        // Function to check if any item in the hierarchy is selected
        const isAnyItemSelected = (filterKey) => {
          return (selectedFilters[filterKey] || []).length > 0;
        };

        // Render the hierarchical structure with checkboxes for all items
        const renderHierarchy = (
          filterKey,
          hierarchyData,
          prefix = '',
          depth = 0
        ) => {
          // Check if any item is selected in the entire hierarchy
          const anyItemSelected = isAnyItemSelected(filterKey);

          // Check if it's the top level call and skip rendering the <ul> for it
          if (prefix === '') {
            return Object.values(hierarchyData).map((subHierarchy, index) =>
              renderHierarchy(
                filterKey,
                subHierarchy,
                Object.keys(hierarchyData)[index] + '_',
                depth + 1 // Increment depth as we go deeper
              )
            );
          } else {
            // Prevent rendering items more than 3 levels deep when no item is selected at all
            if (depth > 3 && !anyItemSelected) {
              return null;
            }

            return (
              <ul style={{ listStyleType: 'none', paddingLeft: '4px' }}>
                {Object.keys(hierarchyData).map((key) => {
                  // If key is 'shoes', skip rendering this line but render its subHierarchy
                  if (key === 'Shoes') {
                    return (
                      <React.Fragment key={key}>
                        {typeof hierarchyData[key] === 'object' &&
                          renderHierarchy(
                            filterKey,
                            hierarchyData[key],
                            `${prefix + key}_`,
                            depth + 1 // Increment depth as we go deeper
                          )}
                      </React.Fragment>
                    );
                  }

                  // Apply bold style if depth is 1
                  const labelStyle = depth === 1 ? { fontWeight: 'bold' } : {};
                  const fullKey = prefix + key;

                  return (
                    <li key={key}>
                      <label>
                        <input
                          type="checkbox"
                          checked={isSelected(filterKey, prefix + key)}
                          onChange={(e) =>
                            handleHierarchyChange(
                              filterKey,
                              fullKey,
                              e.target.checked,
                              hierarchyData[key]
                            )
                          }
                          style={{ marginRight: '8px' }}
                        />
                        <span
                          onClick={() => {
                            const updatedFilters = {
                              ...selectedFilters,
                              [filterKey]: [prefix + key],
                            };
                            navigate(
                              getFilterUrl({
                                selectedFilters: updatedFilters,
                                page: 1,
                              })
                            );
                          }}
                          style={{
                            cursor: 'pointer',
                            userSelect: 'none',
                            ...labelStyle, // Apply the bold style here
                          }}
                        >
                          {key}
                        </span>
                      </label>
                      {/* Recursive call for nested items */}
                      {typeof hierarchyData[key] === 'object' &&
                        renderHierarchy(
                          filterKey,
                          hierarchyData[key],
                          `${prefix + key}_`,
                          depth + 1 // Increment depth as we go deeper
                        )}
                    </li>
                  );
                })}
              </ul>
            );
          }
        };

        return (
          <div>
            <h3>{displayName}</h3>
            {renderHierarchy(configKey, hierarchyData)}
          </div>
        );
      case 'drop_down_menu':
        return (
          <div>
            <h3>{displayName}</h3>
            {/* Flex container for dropdown menus */}
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {/* First dropdown */}
              <Form.Select
                value={selectedFirst}
                onChange={(e) => {
                  const newFirst = e.target.value;
                  setSelectedFirst(newFirst);
                  setSelectedSecond('any'); // Reset second dropdown
                  handleDropdownMenuChange(filterConfig, newFirst, 'any');
                }}
                style={{ width: 'auto', minWidth: '100px' }}
              >
                {firstDropdownOptions.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt === 'any'
                      ? 'Any'
                      : opt.charAt(0).toUpperCase() + opt.slice(1)}
                  </option>
                ))}
              </Form.Select>

              {/* Second dropdown only if first is not 'any' */}
              {selectedFirst !== 'any' && (
                <Form.Select
                  value={selectedSecond}
                  onChange={(e) => {
                    const newSecond = e.target.value;
                    setSelectedSecond(newSecond);
                    handleDropdownMenuChange(
                      filterConfig,
                      selectedFirst,
                      newSecond
                    );
                  }}
                  style={{ width: 'auto', minWidth: '80px' }}
                >
                  <option value="any">Any</option>
                  {options[selectedFirst].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>
          </div>
        );

      // Handle other renderTypes here if needed

      default:
        return null;
    }
  };

  const RenderFilters = ({
    filterConfigs,
    filterRenderItems,
    selectedGroupFilters,
    setSelectedGroupFilters,
  }) => {
    const groupedConfigs = filterConfigs.reduce((acc, config) => {
      if (config.groupName) {
        if (!acc[config.groupName]) {
          acc[config.groupName] = [];
        }
        acc[config.groupName].push(config);
      } else {
        acc[config.key] = [config];
      }
      return acc;
    }, {});

    const handleGroupFilterChange = (groupName, selectedKey) => {
      setSelectedGroupFilters((prev) => ({
        ...prev,
        [groupName]: selectedKey,
      }));

      const groupFilters = filterConfigs.filter(
        (config) => config.groupName === groupName
      );
      if (groupFilters) {
        const updatedFilters = { ...pendingFilters };
        const filterKeysToClear = groupFilters.map((config) => config.key);
        filterKeysToClear.forEach((key) => {
          delete updatedFilters[key];
        });
        setPendingFilters(updatedFilters);
      }
    };

    return (
      <div>
        {Object.keys(groupedConfigs).map((groupKey) => {
          const configs = groupedConfigs[groupKey];
          const isGroup = configs.length > 1;
          let selectedConfigKey = selectedGroupFilters[groupKey];

          if (isGroup && !selectedConfigKey) {
            selectedConfigKey = configs[0].key;
            setSelectedGroupFilters((prev) => ({
              ...prev,
              [groupKey]: selectedConfigKey,
            }));
          }

          return (
            <div key={groupKey}>
              {isGroup ? (
                <div>
                  <h3>{groupKey}</h3>
                  <select
                    value={selectedConfigKey}
                    onChange={(e) =>
                      handleGroupFilterChange(groupKey, e.target.value)
                    }
                  >
                    {configs.map((config) => (
                      <option key={config.key} value={config.key}>
                        {config.displayName}
                      </option>
                    ))}
                  </select>
                  {selectedConfigKey && (
                    <RenderFilter
                      filterConfig={configs.find(
                        (config) => config.key === selectedConfigKey
                      )}
                      filterData={filterRenderItems[selectedConfigKey] || []}
                      groupedFilters={groupedConfigs} // Pass groupedConfigs as groupedFilters
                    />
                  )}
                </div>
              ) : (
                <RenderFilter
                  filterConfig={configs[0]}
                  filterData={filterRenderItems[configs[0].key] || []}
                  groupedFilters={groupedConfigs} // Pass groupedConfigs as groupedFilters
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const filtersModified =
    JSON.stringify(pendingFilters) !== JSON.stringify(selectedFilters);

  const applyFilters = () => {
    navigate(getFilterUrl({ selectedFilters: pendingFilters, page: 1 }));
  };

  const handleSortChange = (newOrder) => {
    navigate(getFilterUrl({ order: newOrder, page: 1 }));
  };

  const [innerSearchTerm, setInnerSearchTerm] = useState('');

  const handleInnerSearch = (e) => {
    if (e) e.preventDefault();
    const baseUrl = `/search?query=${encodeURIComponent(
      innerSearchTerm
    )}&order=${order}&page=1`;

    // Construct a query string from the selected filters
    const filterQueryString = Object.entries(selectedFilters)
      .reduce((acc, [key, values]) => {
        if (values.length > 0) {
          acc.push(`${key}=${values.map(encodeURIComponent).join(',')}`);
        }
        return acc;
      }, [])
      .join('&');

    // Append the filters and the newSearch parameter to the base URL
    const finalUrl = `${baseUrl}${
      filterQueryString ? '&' + filterQueryString : ''
    }&newSearch=no`;

    // Navigate to the updated URL
    navigate(finalUrl);
  };

  const handlePageChange = (e) => {
    const selectedPage = e.target.value;
    navigate(
      getFilterUrl({ page: selectedPage, selectedFilters: pendingFilters })
    );
  };

  const clearAllFilters = () => {
    navigate(`/search?query=all&order=${order}&page=1`);
  };

  // State to manage the visibility of filters on mobile devices
  const [showFilters, setShowFilters] = useState(false);

  if (filtersLoading || Object.keys(filterRenderItems).length === 0) {
    return <LoadingBox />;
  }

  return (
    <div className="search-screen">
      <Helmet>
        <title>FindFast.ai</title>
      </Helmet>

      {/* Filter toggle button for mobile devices */}
      <Row className="d-md-none mb-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Col>
      </Row>

      {/* Conditionally render filters on mobile devices */}
      {showFilters && (
        <div className="filters-mobile d-md-none">
          {/* The Apply Filters button, visible only if filtersModified is true */}
          {filtersModified && (
            <div
              style={{
                position: 'sticky',
                top: '100px',
                left: '20px',
                zIndex: 9999,
              }}
            >
              <Button variant="primary" onClick={applyFilters}>
                Apply Filters
              </Button>
            </div>
          )}

          {/* Search within results UI */}
          <div className="mb-3">
            <Form
              className="d-flex me-auto"
              onSubmit={(e) => handleInnerSearch(e)}
            >
              <InputGroup>
                <FormControl
                  type="text"
                  name="query"
                  id="query"
                  value={innerSearchTerm}
                  onChange={(e) => setInnerSearchTerm(e.target.value)}
                  placeholder="Search within results..."
                  aria-label="Search within results..."
                  aria-describedby="button-search"
                ></FormControl>
                <Button variant="info" type="submit" id="button-search">
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroup>
            </Form>
          </div>

          <RenderFilters
            filterConfigs={filterConfigs}
            filterRenderItems={filterRenderItems}
            selectedGroupFilters={{}}
            setSelectedGroupFilters={() => {}}
          />
        </div>
      )}

      <Row>
        {/* Filters column for desktop devices */}
        <Col md={3} className="d-none d-md-block">
          {/* The Apply Filters button, visible only if filtersModified is true */}
          {filtersModified && (
            <div
              style={{
                position: 'sticky',
                top: '100px',
                left: '20px',
                zIndex: 9999,
              }}
            >
              <Button variant="primary" onClick={applyFilters}>
                Apply Filters
              </Button>
            </div>
          )}

          {/* Search within results UI */}
          <div className="mb-3">
            <Form
              className="d-flex me-auto"
              onSubmit={(e) => handleInnerSearch(e)}
            >
              <InputGroup>
                <FormControl
                  type="text"
                  name="query"
                  id="query"
                  value={innerSearchTerm}
                  onChange={(e) => setInnerSearchTerm(e.target.value)}
                  placeholder="Search within results..."
                  aria-label="Search within results..."
                  aria-describedby="button-search"
                ></FormControl>
                <Button variant="info" type="submit" id="button-search">
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroup>
            </Form>
          </div>
          <RenderFilters
            filterConfigs={filterConfigs}
            filterRenderItems={filterRenderItems}
            selectedGroupFilters={{}}
            setSelectedGroupFilters={() => {}}
          />
        </Col>

        <Col md={9}>
          {loading && <LoadingBox></LoadingBox>}
          {error && <MessageBox variant="danger">{error}</MessageBox>}
          {!loading && !error && (
            <>
              <Row className="justify-content-between mb-3">
                <Col md={6}>
                  <div>
                    {countProducts === 0 ? 'No' : countProducts} Results
                    {(Object.keys(selectedFilters).length > 0 ||
                      innerSearchTerm ||
                      (query && query !== 'all')) && (
                      <Button variant="light" onClick={clearAllFilters}>
                        Clear all filters
                      </Button>
                    )}
                  </div>
                </Col>
                <Col className="text-end">
                  Sort by{' '}
                  <select
                    value={order}
                    onChange={(e) => {
                      handleSortChange(e.target.value);
                    }}
                    className="custom-select-box"
                  >
                    {sortParameters.map((param) => {
                      const className = param.label.includes('FindFast')
                        ? 'find-fast-option'
                        : '';
                      return (
                        <option
                          key={param.value}
                          value={param.value}
                          className={className}
                        >
                          {param.label}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              {products.length === 0 && (
                <MessageBox>No Product Found</MessageBox>
              )}

              <Row>
                {products.map((product) => (
                  <Col xs={6} sm={6} lg={4} className="mb-3" key={product._id}>
                    <Product
                      product={product}
                      selectedFilters={selectedFilters}
                    ></Product>
                  </Col>
                ))}
              </Row>
              <div className="pagination">
                <select
                  className="form-select"
                  value={page}
                  onChange={handlePageChange}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {[...Array(pages).keys()].map((x) => (
                    <option key={x + 1} value={x + 1}>
                      Page {x + 1}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
