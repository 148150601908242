import React, { useContext, useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Rating from './Rating';
import axios from 'axios';
import './Product.css';

function Product(props) {
  const { product, selectedFilters } = props;
  const navigate = useNavigate(); // Initialize useNavigate

  const [timeLeft, setTimeLeft] = useState(null);

  const dealHasNotEnded =
    product.deals && product.deals.ends_at * 1000 > Date.now();

  useEffect(() => {
    let timer;
    if (product.deals && product.deals.ends_at * 1000 > Date.now()) {
      const updateTimer = () => {
        const currentTime = Date.now();
        const endTime = product.deals.ends_at * 1000;
        const timeRemaining = endTime - currentTime;
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
        } else {
          setTimeLeft(0);
          clearInterval(timer);
        }
      };
      updateTimer();
      timer = setInterval(updateTimer, 1000);
      return () => clearInterval(timer);
    }
  }, [product.deals]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      '0'
    );
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const constructProductLink = (asin) => {
    return `/product/${asin}`; // Use relative path within your app
  };

  const findVariantLink = () => {
    if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
      return [
        constructProductLink(product.asin),
        product.main_image.link,
        null,
      ];
    }

    const variantFilters = Object.entries(selectedFilters).filter(([key, _]) =>
      key.startsWith('variants.')
    );
    if (variantFilters.length === 0) {
      return [
        constructProductLink(product.asin),
        product.main_image.link,
        null,
      ];
    }

    const matchingVariants = [];

    for (const variant of product.variants) {
      let matchesAll = variantFilters.every(([filterKey, filterValues]) => {
        const propertyPath = filterKey.split('.').slice(1); // Get the path after 'variants.'
        let variantValue = variant;

        // Traverse the property path
        for (const pathPart of propertyPath) {
          if (variantValue && variantValue.hasOwnProperty(pathPart)) {
            variantValue = variantValue[pathPart];
          } else {
            variantValue = undefined;
            break;
          }
        }

        if (variantValue === undefined) {
          return false;
        }

        // Ensure both values are strings for comparison
        return filterValues.includes(String(variantValue));
      });
      if (matchesAll) {
        matchingVariants.push(variant);
      }
    }

    if (matchingVariants.length > 0) {
      // Filter variants with valid pa_price
      const variantsWithPrice = matchingVariants.filter(
        (v) => v.pa_price !== null && v.pa_price !== undefined
      );

      let selectedVariant;

      if (variantsWithPrice.length > 0) {
        // Find the variant with the lowest pa_price
        selectedVariant = variantsWithPrice.reduce((lowest, variant) =>
          parseFloat(variant.pa_price) < parseFloat(lowest.pa_price)
            ? variant
            : lowest
        );
      } else {
        // No variants have valid pa_price, pick the first matching variant
        selectedVariant = matchingVariants[0];
      }

      const imageUrl =
        selectedVariant.image_url && selectedVariant.image_url.trim() !== ''
          ? selectedVariant.image_url
          : product.main_image.link;

      return [
        constructProductLink(selectedVariant.asin),
        imageUrl,
        selectedVariant,
      ];
    }

    return [constructProductLink(product.asin), product.main_image.link, null]; // Fallback if no variants match
  };

  const [variantLink, imageLink, matchingVariant] = findVariantLink();

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1; // Default to page 1 if no page is specified
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage, // Ensure page is included in the query parameters
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  const isVariantFilterSelected = () => {
    return (
      selectedFilters &&
      Object.keys(selectedFilters).some((key) => key.startsWith('variants.'))
    );
  };

  return (
    <Card className="custom-card">
      <a
        href={variantLink}
        onClick={(e) => {
          e.preventDefault();
          navigate(variantLink);
        }}
        style={{ textDecoration: 'none' }}
      >
        <img
          src={imageLink}
          className="card-img-top"
          alt={product.name}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectFit: 'scale-down',
          }}
        />
      </a>
      <Card.Body>
        <a
          href={variantLink}
          onClick={(e) => {
            e.preventDefault();
            navigate(variantLink);
          }}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Card.Title
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              height: '96px',
              cursor: 'pointer',
            }}
          >
            {product.title}
          </Card.Title>
        </a>
        <Rating rating={product.rating} numReviews={product.ratings_total} />

        {isVariantFilterSelected() ? (
          matchingVariant && matchingVariant.pa_price ? (
            // Use matchingVariant.pa_price and calculate savings
            <Card.Text>
              {matchingVariant.pa_list_price &&
              matchingVariant.pa_list_price > matchingVariant.pa_price ? (
                <>
                  <span style={{ color: 'red' }}>
                    -
                    {Number(
                      (
                        ((matchingVariant.pa_list_price -
                          matchingVariant.pa_price) /
                          matchingVariant.pa_list_price) *
                        100
                      ).toFixed(0)
                    )}
                    %
                  </span>{' '}
                </>
              ) : null}
              ${Number(matchingVariant.pa_price).toFixed(2)}
              <br />
              <span style={{ fontSize: 'small', color: 'grey' }}>
                as of{' '}
                {new Date(matchingVariant.pa_timestamp * 1000).toLocaleString(
                  [],
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                )}
              </span>
            </Card.Text>
          ) : (
            // Use price range as in existing code
            <Card.Text>
              ${Number(0.8 * product.price).toFixed(2)} - $
              {Number(1.8 * product.price).toFixed(2)}
              <br />
              <span style={{ fontSize: 'small', color: 'grey' }}>
                Price depends on size/color combo
              </span>
            </Card.Text>
          )
        ) : product.deals && dealHasNotEnded ? (
          <Card.Text>
            <span style={{ color: 'red' }}>
              -{Number(product.deals_saving_percentage)}%
            </span>{' '}
            ${Number(product.price).toFixed(2)}
            {timeLeft !== null && timeLeft < 12 * 60 * 60 * 1000 && (
              <div
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  fontSize: 'smaller',
                  display: 'inline-block',
                  padding: '2px 4px',
                  marginLeft: '5px',
                }}
              >
                Ends in {formatTimeLeft(timeLeft)}
              </div>
            )}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.deals ? (
          // Deal has ended
          <Card.Text>
            ${Number(product.deals.list_price_value).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.price ? (
          // No deals
          <Card.Text>
            ${Number(product.price).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }} // Add cursor pointer style
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}
      </Card.Body>
    </Card>
  );
}
export default Product;
