import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable'; // Imported for swipe functionality
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import './Product.css';

function ProductDetails(props) {
  const { product, asin } = props;

  const [timeLeft, setTimeLeft] = useState(null);

  const dealHasNotEnded =
    product.deals && product.deals.ends_at * 1000 > Date.now();

  useEffect(() => {
    let timer;
    if (product.deals && product.deals.ends_at * 1000 > Date.now()) {
      const updateTimer = () => {
        const currentTime = Date.now();
        const endTime = product.deals.ends_at * 1000;
        const timeRemaining = endTime - currentTime;
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
        } else {
          setTimeLeft(0);
          clearInterval(timer);
        }
      };
      updateTimer();
      timer = setInterval(updateTimer, 1000);
      return () => clearInterval(timer);
    }
  }, [product.deals]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      '0'
    );
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const associateID = 'findfast0a-20'; // Replace with your actual Amazon Associates ID

  // Modified constructAmazonLink function with app_redirect=true
  const constructAmazonLink = (asin) => {
    return `https://www.amazon.com/dp/${asin}/?tag=${associateID}&app_redirect=true`;
  };

  const findVariantLink = () => {
    for (const variant of product.variants) {
      if (variant.asin === asin) {
        const imageUrl =
          variant.pa_image_url && variant.pa_image_url.trim() !== ''
            ? variant.pa_image_url
            : variant.image_url && variant.image_url.trim() !== ''
            ? variant.image_url
            : product.main_image.link;
        return [
          constructAmazonLink(variant.asin),
          !asin || asin === product.asin,
          variant,
        ];
      }
    }

    return [
      constructAmazonLink(product.asin),
      !asin || asin === product.asin,
      null,
    ];
  };

  const [variantLink, main_asin, variant] = findVariantLink();

  const isVariantSelected = !main_asin; // Determine if a variant is selected

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1;
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage,
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  const getColor = (value) => {
    switch (value) {
      case 'Positive':
        return 'green';
      case 'Mixed':
        return 'orange';
      case 'Negative':
        return 'red';
      default:
        return 'black';
    }
  };

  const skip_rows = [
    'Department',
    'ASIN',
    'Best Sellers Rank',
    'size',
    'color',
    'Country of Origin',
  ];

  const columnStyle = { width: '50%' };

  // New code for Available Colors table with size category and available sizes
  const sizeCategories = [
    'size_men',
    'size_women',
    'size_kids',
    'size_toddlers',
  ];
  const categoryMap = {
    size_men: 'Men',
    size_women: 'Women',
    size_kids: 'Kids',
    size_toddlers: 'Toddlers',
  };

  const [availableSizeCategories, setAvailableSizeCategories] = useState([]);
  const [selectedSizeCategory, setSelectedSizeCategory] = useState('');
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [availableColors, setAvailableColors] = useState([]);

  // Find the selected item based on asin
  const selectedItem = product.variants.find((item) => item.asin === asin);

  // Initialize available size categories and selected size category
  useEffect(() => {
    const categoriesSet = new Set();
    product.variants.forEach((item) => {
      if (item.pa_price) {
        sizeCategories.forEach((category) => {
          if (item[category]) {
            categoriesSet.add(category);
          }
        });
      }
    });
    const categoriesArray = Array.from(categoriesSet);
    setAvailableSizeCategories(categoriesArray);

    // Set default selected size category based on selected item
    const initialSizeCategory = sizeCategories.find(
      (category) => selectedItem && selectedItem[category]
    );
    setSelectedSizeCategory(initialSizeCategory || categoriesArray[0]);
  }, [product.variants, selectedItem]);

  // Update available sizes and selected size when size category changes
  useEffect(() => {
    if (selectedSizeCategory) {
      const sizesSet = new Set();
      product.variants.forEach((item) => {
        if (item.pa_price && item[selectedSizeCategory]) {
          sizesSet.add(item[selectedSizeCategory]);
        }
      });
      const sizesArray = Array.from(sizesSet);

      // Sort sizes numerically if possible
      sizesArray.sort((a, b) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        const isNumA = !isNaN(numA);
        const isNumB = !isNaN(numB);

        if (isNumA && isNumB) {
          return numA - numB;
        } else if (isNumA && !isNumB) {
          return -1;
        } else if (!isNumA && isNumB) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      setAvailableSizes(sizesArray);

      // Set default selected size
      if (selectedItem && selectedItem[selectedSizeCategory]) {
        setSelectedSize(selectedItem[selectedSizeCategory]);
      } else {
        // Set median size if no selected item
        const medianIndex = Math.floor(sizesArray.length / 2);
        setSelectedSize(sizesArray[medianIndex] || sizesArray[0]);
      }
    }
  }, [selectedSizeCategory, product.variants, selectedItem]);

  // Update available colors when size category or selected size changes
  useEffect(() => {
    if (selectedSizeCategory && selectedSize) {
      // Find matching items with selected size category and selected size
      const matchingItems = product.variants.filter((item) => {
        if (!item.pa_price) return false;
        if (!item[selectedSizeCategory]) return false;
        // Normalize the sizes for comparison
        const itemSize = item[selectedSizeCategory]
          .toString()
          .trim()
          .toLowerCase();
        const selectedSizeNormalized = selectedSize
          .toString()
          .trim()
          .toLowerCase();
        return itemSize === selectedSizeNormalized;
      });
      matchingItems.sort((a, b) => a.pa_price - b.pa_price);
      setAvailableColors(matchingItems);
    }
  }, [selectedSizeCategory, selectedSize, product.variants]);

  // New state and handlers for image sliding
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    // Build the image URLs array
    let images = [];
    if (variant) {
      if (variant.pa_image_url && variant.pa_image_url.trim() !== '') {
        images.push(variant.pa_image_url);
      } else if (variant.image_url && variant.image_url.trim() !== '') {
        images.push(variant.image_url);
      } else {
        images.push(product.main_image.link);
      }

      if (variant.pa_image_url_array && variant.pa_image_url_array.length > 0) {
        images = images.concat(variant.pa_image_url_array);
      }
    } else {
      images.push(product.main_image.link);
    }
    setImageUrls(images);
    setCurrentImageIndex(0); // Reset to first image when imageUrls change
  }, [variant, product.main_image.link]);

  // Handlers for swipe actions
  const handleSwipedLeft = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleSwipedRight = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    trackMouse: true, // Enables mouse events
  });

  // Functions to handle arrow clicks
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  // **Added function to handle Amazon link clicks**
  const handleAmazonLinkClick = (event, url) => {
    event.preventDefault();
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16796520804/QSf_COmfkO8ZEOSimsk-',
        value: 1.0,
        currency: 'USD',
      });
    }
    setTimeout(() => {
      window.open(url, '_blank');
    }, 200);
  };

  return (
    <Card
      className="custom-card"
      style={{ maxWidth: '500px', margin: '0 auto' }}
    >
      <a
        href={variantLink}
        onClick={(e) => handleAmazonLinkClick(e, variantLink)}
        style={{ cursor: 'pointer' }}
      >
        <div {...handlers} style={{ position: 'relative' }}>
          <img
            src={imageUrls[currentImageIndex]}
            className="card-img-top"
            alt={product.name}
            style={{
              height: '300px',
              width: '100%',
              objectFit: 'cover',
              objectFit: 'scale-down',
            }}
          />
          {/* Left Arrow */}
          {imageUrls.length > 1 && (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handlePrevImage();
              }}
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                fontSize: '2rem',
                color: 'black',
                cursor: 'pointer',
                userSelect: 'none',
                zIndex: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                padding: '5px',
                borderRadius: '50%',
              }}
            >
              &#8249;
            </div>
          )}
          {/* Right Arrow */}
          {imageUrls.length > 1 && (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleNextImage();
              }}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                fontSize: '2rem',
                color: 'black',
                cursor: 'pointer',
                userSelect: 'none',
                zIndex: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                padding: '5px',
                borderRadius: '50%',
              }}
            >
              &#8250;
            </div>
          )}
          {/* Image Indicators */}
          {imageUrls.length > 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              {imageUrls.map((_, index) => (
                <div
                  key={index}
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    backgroundColor:
                      index === currentImageIndex ? 'black' : 'grey',
                    margin: '0 5px',
                  }}
                ></div>
              ))}
            </div>
          )}
        </div>
      </a>
      <Card.Body>
        <a
          href={variantLink}
          onClick={(e) => handleAmazonLinkClick(e, variantLink)}
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Card.Title
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              height: '96px',
            }}
          >
            {product.title}
          </Card.Title>
        </a>
        <Rating rating={product.rating} numReviews={product.ratings_total} />

        {/* Pricing and Deal Information */}
        {isVariantSelected ? (
          variant && variant.pa_price ? (
            // Use variant.pa_price and calculate savings
            <Card.Text>
              {variant.pa_list_price &&
              variant.pa_list_price > variant.pa_price ? (
                <>
                  <span style={{ color: 'red' }}>
                    -
                    {Number(
                      (
                        ((variant.pa_list_price - variant.pa_price) /
                          variant.pa_list_price) *
                        100
                      ).toFixed(0)
                    )}
                    %
                  </span>{' '}
                </>
              ) : null}
              ${Number(variant.pa_price).toFixed(2)}
              <br />
              <span style={{ fontSize: 'small', color: 'grey' }}>
                as of{' '}
                {new Date(variant.pa_timestamp * 1000).toLocaleString([], {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </Card.Text>
          ) : (
            // Use price range as in existing code
            <Card.Text>
              ${Number(0.8 * product.price).toFixed(2)} - $
              {Number(1.8 * product.price).toFixed(2)}
              <br />
              <span style={{ fontSize: 'small', color: 'grey' }}>
                Price depends on size/color combo
              </span>
            </Card.Text>
          )
        ) : product.deals && dealHasNotEnded ? (
          <Card.Text>
            <span style={{ color: 'red' }}>
              -{Number(product.deals_saving_percentage)}%
            </span>{' '}
            ${Number(product.price).toFixed(2)}
            {timeLeft !== null && timeLeft < 12 * 60 * 60 * 1000 && (
              <div
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  fontSize: 'smaller',
                  display: 'inline-block',
                  padding: '2px 4px',
                  marginLeft: '5px',
                }}
              >
                Ends in {formatTimeLeft(timeLeft)}
              </div>
            )}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.deals ? (
          // Deal has ended
          <Card.Text>
            ${Number(product.deals.list_price_value).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.price ? (
          // No deals
          <Card.Text>
            ${Number(product.price).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {/* Bestsellers Rank */}
        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}

        {/* Available Colors Table with size category and sizes */}
        {availableColors.length > 0 && (
          <div className="mt-3">
            <h5>
              Available Colors for{' '}
              <select
                value={selectedSizeCategory}
                onChange={(e) => setSelectedSizeCategory(e.target.value)}
              >
                {availableSizeCategories.map((category) => (
                  <option key={category} value={category}>
                    {categoryMap[category]}
                  </option>
                ))}
              </select>{' '}
              size{' '}
              <select
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                {availableSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </h5>
            <Table striped bordered hover size="sm">
              <tbody>
                {availableColors.map((item, index) => (
                  <tr key={index}>
                    <td colSpan="3" style={{ padding: 0 }}>
                      <a
                        href={constructAmazonLink(item.asin)}
                        onClick={(e) =>
                          handleAmazonLinkClick(
                            e,
                            constructAmazonLink(item.asin)
                          )
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: 'inherit',
                          padding: '8px',
                        }}
                      >
                        <div style={{ flex: 1 }}>{item.pa_color}</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                          <img
                            src={item.pa_image_url}
                            alt={item.pa_color}
                            style={{
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                          ${Number(item.pa_price).toFixed(2)}
                          {item.pa_list_price &&
                          item.pa_list_price > item.pa_price &&
                          ((item.pa_list_price - item.pa_price) /
                            item.pa_list_price) *
                            100 >
                            4 ? (
                            <>
                              <br />
                              <span style={{ color: 'red' }}>
                                -
                                {Number(
                                  (
                                    ((item.pa_list_price - item.pa_price) /
                                      item.pa_list_price) *
                                    100
                                  ).toFixed(0)
                                )}
                                %
                              </span>
                            </>
                          ) : null}
                        </div>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {/* Customer feedback table */}
        {product.customers_say && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Attribute</th>
                <th style={columnStyle}>Customer Opinion</th>
              </tr>
            </thead>
            <tbody>
              {product.customers_say.map((feedback, index) => (
                <tr key={index}>
                  <td style={columnStyle}>{feedback.name}</td>
                  <td
                    style={{ color: getColor(feedback.value), ...columnStyle }}
                  >
                    {feedback.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* Specifications table */}
        {product.specifications_wo_map && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Specification</th>
                <th style={columnStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={columnStyle}>Brand</td>
                <td style={columnStyle}>{product.brand}</td>
              </tr>
              {product.specifications_wo_map
                .filter((spec) => !skip_rows.includes(spec.name))
                .map((spec, index) => (
                  <tr key={index}>
                    <td style={columnStyle}>{spec.name}</td>
                    <td style={columnStyle}>{spec.value}</td>
                  </tr>
                ))}
              <tr>
                <td style={columnStyle}>Date first available</td>
                <td style={columnStyle}>
                  {new Date(
                    product.date_first_available * 1000
                  ).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProductDetails;
