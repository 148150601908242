import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useNavigate } from 'react-router-dom';

export default function SearchBox() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const learnMoreRef = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    navigate(query ? `/search/?query=${query}` : '/search');
  };

  useEffect(() => {
    // Determine if user is on mobile or desktop
    const checkIfMobile = () => {
      // Simple check: if screen width < 768px consider it mobile
      return window.innerWidth < 768;
    };
    setIsMobile(checkIfMobile());

    // Check if user has seen the popup before
    const hasSeenPopover = localStorage.getItem('hasSeenPopover');
    if (!hasSeenPopover) {
      setShowPopover(true);
    }
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowPopover(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowPopover(false);
      localStorage.setItem('hasSeenPopover', 'true');
    }
  };

  const handleClick = () => {
    if (isMobile) {
      setShowPopover(true);
    }
  };

  const handleClose = () => {
    setShowPopover(false);
    localStorage.setItem('hasSeenPopover', 'true');
  };

  const popoverContent = (
    <Popover id="learn-more-popover">
      <Popover.Header as="h3">How We Rank Products</Popover.Header>
      <Popover.Body>
        Our AI powered algorithm carefully analyzes product sales data, customer
        reviews, price fluctuations, availability, specs and features to show
        you the best products for the filters you select on our website. We
        strive to gain your trust in showing you the best products. Therefore,
        none of the products are sponsored. If you buy on Amazon.com using a
        link from our website, we make a small commission directly from Amazon.
        <div className="text-end mt-2">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Form className="d-flex me-auto" onSubmit={submitHandler}>
      <InputGroup>
        <FormControl
          type="text"
          name="q"
          id="q"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="search shoes..."
          aria-label="Search shoes"
          aria-describedby="button-search"
        />
        <Button variant="info" type="submit" id="button-search">
          <i className="fas fa-search"></i>
        </Button>
        <div className="ms-2" style={{ color: 'white' }}>
          Find shoes on Amazon.com
          <span
            ref={learnMoreRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={{
              textDecoration: 'underline dashed',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
          >
            Learn more
          </span>
          <br /> We rank the products to help you find the best ones
        </div>
      </InputGroup>

      <Overlay
        target={learnMoreRef.current}
        show={showPopover}
        placement="bottom"
      >
        {popoverContent}
      </Overlay>
    </Form>
  );
}
